import React from "react"
import globalStyles from '../../style/global.module.scss'
import { FormattedMessage } from 'gatsby-plugin-intl'


const CallToAction = ({ mode, text = "Home.calltoaction" }) => {

    function getBtnClass() {
        return mode === "dark" ? globalStyles.callToActionButtonDark : globalStyles.colorbuttonLight
    }

    return (
        <button className={getBtnClass()} >
            <a href="../contact" >
                <span><FormattedMessage id={text} /> </span>
            </a>
        </button>
    )
}

export default CallToAction
