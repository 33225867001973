import React from "react"
import globalStyles from '../../style/global.module.scss'

const contentSection = ({ children }) => {
    return (
        <section className={globalStyles.contentSectionContainer}>
                {children}
        </section>
    )
}

export default contentSection
