import React from "react"
import globalStyles from '../../style/global.module.scss'
import CallToAction from './calltoaction.js'


const renderSubtitle = (subtitle, showCallToAction = true) => {
    if (showCallToAction) return (<CallToAction mode="dark" text={subtitle} />)
    else return (<p className={globalStyles.subtitle}>{subtitle}</p>)
}

const landingSection = ({ h1, title, subtitle, showCallToAction = true }) => {
    return (
        <section className={globalStyles.landingSectionContainer}>
            <div data-sal={"slide-right"}
                data-sal-delay={"200"}
                data-sal-duration={"500"}

                className={globalStyles.landingSection}>
                <h1>{h1}</h1>
                <p className={globalStyles.title}>{title}</p>

                {renderSubtitle(subtitle, showCallToAction)}

            </div>
        </section>
    )
}

export default landingSection
