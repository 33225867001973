import React, { useEffect, useState } from "react"
import { useInView} from "react-intersection-observer"
import styled from "styled-components"

const Slide = styled.div`
transform: ${({ animate, transform }) => animate ? "translateX(0);" : transform};
opacity: ${({ animate }) => animate ? "1" : "0"};
transition: all 1s;
`

const PricingCard = (props) => {

    const [ref, inView] = useInView(.1)
    const [animate, setAnimate] = useState(false)
    const [wasTriggered, setWasTriggered] = useState(false)



    useEffect(() => {
        if(inView){
            if(!wasTriggered){
                setAnimate(true)
                setWasTriggered(true)
            } 
        }
    }, [ref, inView, wasTriggered])

    function getTransitionStyle(){

        let style = {}
        if(props.duration) style.transitionDuration = props.duration
        if(props.delay) style.transitionDelay= props.delay

        return style
        
    }

    function getTransitionDirection() {
        return props.right ? "translateX(100%)" : "translateX(-100%)"
    }



    return(
        <Slide style={getTransitionStyle()} ref={ref} animate={animate} transform={getTransitionDirection()}>
            {props.children}
        </Slide>
        )

}

export default PricingCard
