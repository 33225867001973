import React from "react"
import Layout from '../components/global/layout.js'
import teamStyles from '../style/team.module.scss'
import { FormattedMessage } from 'gatsby-plugin-intl'
import LandingSection from "../components/global/landingsection.js"
import ContentSection from "../components/global/contentsection.js"
import { Helmet } from "react-helmet"
import TeamCard from "../components/team/team.card.js"
import PatrickImg from "../img/team/patrick.png"
import ManuelImg from "../img/team/manuel.png"


const TeamPage = () => {

    function getYears(year) {
        let d = new Date()
        let y = d.getFullYear()
        let experience = y - year
        return experience
    }

    return (
        <Layout>

            <Helmet>
                <meta charSet="utf-8" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                <title>Spherical Elephant | Team</title>
                <meta name="description" content="Lerne uns kennen! IT Agentur in 1190 Wien." />
            </Helmet>

            <LandingSection
                h1={<FormattedMessage id="Team.h1" />}
                title={<FormattedMessage id="Team.title" />}
                subtitle={<FormattedMessage id="Team.subtitle" />}
            />
            <ContentSection>

                <div className={teamStyles.contentGrid}>
                    <TeamCard image={PatrickImg} color="#77CDE1" name={<FormattedMessage id={"Team.patrick.name"} />} position={<FormattedMessage id={"Team.patrick.position"} />} bio={<FormattedMessage id={"Team.patrick.bio.1"} />} years={getYears(2011)} bio2={<FormattedMessage id={"Team.patrick.bio.2"} />} linkedIn="https://www.linkedin.com/in/patrick-sturm-a6312186" />
                    <TeamCard image={ManuelImg} color="#A4C7C6" name={<FormattedMessage id={"Team.manuel.name"} />} position={<FormattedMessage id={"Team.manuel.position"} />} bio={<FormattedMessage id={"Team.manuel.bio"} />} linkedIn="https://www.linkedin.com/in/manuel-holczmann-00b40b13b/" />
                </div>


            </ContentSection>

        </Layout>

    )
}

export default TeamPage
