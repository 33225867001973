import React, { useState } from "react"
import teamStyles from "../../style/team.module.scss"
import LinkedIn from "../../img/team/linkedIn.svg"
import LinkedInHover from "../../img/team/linkedIn_hover.svg"
import LazySlide from "../global/lazy.slide.js"
import Image from "../global/image.js"


const TeamCard = (props) => {
    let [imageHover, setImageHover] = useState(false)
    function getImageSrc(){
        return imageHover ? LinkedInHover : LinkedIn
    }

    function showLinkedIn() {
        if (props.linkedIn) {
            return <span >
                <a onMouseEnter={() => setImageHover(true)} onMouseLeave={() => setImageHover(false)} target="_blank" href={props.linkedIn} rel="noreferrer">
                    <img src={getImageSrc()} alt="LinkedIn Logo" />Let's connect!</a></span>
        }

        else {
            return
        }
    }

    return (
        <LazySlide>
            <div className={teamStyles.cardWrapper}>
                <div className={teamStyles.imageContainer}>
                    <div style = {{background: props.color}} className={teamStyles.colorCircle} />
                    <Image src={props.image} alt="Team.pixel.alt" />
                </div>

                <div className={teamStyles.textContainer}>
                    <h3>{props.name}</h3>
                    <h4>{props.position}</h4>
                    <p>{props.bio}{props.years}{props.bio2}</p>
                    {showLinkedIn()}
                </div>
            </div>
        </LazySlide>
    )
}

export default TeamCard
